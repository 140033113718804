import React, { useEffect, useContext } from "react"

import GlobalStateProvider, { GlobalDispatchContext } from "../context/GlobalStateProvider"
import GlobalStateDisplay from "./GlobalStateDisplay"
import GlobalEventListener from "./GlobalEventListener"

// import ExitIntentPopup from "./ExitIntentPopup"
import BottomBanner from "./BottomBanner"

import { BannerVisibilityProvider } from "../context/BannerVisibilityContext"

import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"
import "@fontsource/ubuntu/400.css"
import "@fontsource/ubuntu/700.css"

import "../styles/global.css"

const getUtmParams = search => {
	const params = new URLSearchParams(search)
	const utmParams = {}

	for (const [key, value] of params.entries()) {
		if (key.startsWith("utm_")) {
			utmParams[key] = value
		}
	}

	return utmParams
}

const UTMHandler = () => {
	const { setState } = useContext(GlobalDispatchContext)

	useEffect(() => {
		const utmParams = getUtmParams(window.location.search)
		setState(prevState => ({
			...prevState,
			utmParams,
		}))
	}, [setState])

	return null
}

/** This component wraps the whole application in App/Test/Storybook environments. Pass all global providers here and add global imports at the top of the file */
const RootWrapper = ({ children }) => {
	return (
		<GlobalStateProvider>
			<BannerVisibilityProvider>
				<UTMHandler />
				<GlobalEventListener />

				<div className="min-w-[320px] overflow-hidden">{children}</div>

				<BottomBanner id="PART-OF-LRDS" />

				{process.env.NODE_ENV === "development" && <GlobalStateDisplay />}
				{/* <ExitIntentPopup /> */}
			</BannerVisibilityProvider>
		</GlobalStateProvider>
	)
}

export default RootWrapper
